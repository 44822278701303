<template>
  <div>
    <div class="container sm:px-10" style="padding: 0">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen" style="padding: 40px">
          <a class="-intro-x flex items-center pt-5">
            <img alt="" class="w-8" :src="require(`@/assets/images/logo.svg`)" />
            <span class="text-white text-lg ml-3">智莺支付联盟</span>
          </a>
          <div class="my-auto" style="margin-right: 200px">
            <img
              style="margin: 0 auto 20px"
              class="-intro-x w-1/2 -mt-16 loginImg"
              :src="require(`@/assets/images/sapiens.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-3xl leading-tight"
              style="font-size: 1.4vw; margin-top: 0.9vw"
            >
              智莺支付联盟助你一键接入 
              支付宝、微信、各大优惠活动
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 px-5 xl:py-0 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
            style="margin: auto auto"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              找回密码
            </h2>

            <div class="intro-x mt-8">
              <div class="phone-box">
                <input
                  v-model="info.phone"
                  type="text"
                  class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                  placeholder="手机号"
                  :disabled="info.phone_input"
                />
                <span class="getYZM" @click="sendMobile">{{
                  btnMsg == null ? countNum + "s后重新发送" : btnMsg
                }}</span>
              </div>
              <input
                v-model="info.psw"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="请输入新密码"
              />
              <input
                v-model="info.psw2"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="新密码确认"
              />

              <input
                v-model="info.code"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="验证码"
              />
            </div>

            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
                @click="getRegister"
              >
                修改
              </button>
              <button
                class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0 align-top"
                @click="getSignUp"
              >
                登录
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import request from "../utils/request";

import lodash from "lodash";

export default {
  data() {
    return {
      // 倒计时周期
      countNum: 60,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      // 定时器
      intervalBtn: {},
      //默认按钮的值
      btnMsg: "发送验证码",
      info: {
        phone: "",
        input: "",
        psw: "",
        psw2: "",
        Label: false,
        phone_input: false,
        code: "",
      },
    };
  },
  mounted() {
    document.body.style.padding = 0;
    cash("body").removeClass("app").addClass("login");
  },
  methods: {
    getSignUp: function () {
      this.$router.push("/login");
    },
    // 倒计时
    countDown() {
      // 设置btn倒计时时显示的信息
      this.btnMsg = null;
      // 更改btn状态
      this.countFlag = !this.countFlag;
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnMsg = "发送验证码";

          // 清除定时器
          clearInterval(this.intervalBtn);
          // 更改btn状态
          this.countFlag = !this.countFlag;
          // 重置倒计时状态
          this.countNum = 60;
        }
        // 倒计时
        this.countNum--;
      }, 1000);
    },
    // 获取验证码 --- > 防抖
    sendMobile: lodash.debounce(function () {
      if (this.btnMsg == null) {
        return;
      }

      //获取输入手机号码
      let phone = this.info.phone;
      //正则校验手机号是否合法
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
        this.$message.error("手机号错误");
        return;
      }
      this.countDown();

      request({
        url: "/smsMsg/updatePasswordSmsMsg",
        method: "POST",
        data: {
          phone: this.info.phone,
        },
      }).then(() => {
        //触发定时器方法
        this.info.phone_input = true;
      });
    }),

    getRegister: lodash.debounce(function () {
      // if (!/^[A-Za-z]{1}[A-Za-z0-9_-]{3,15}$/.test(this.info.input)) {
      //   // 账号检验
      //   this.$message.error(
      //     "请输入正确的账号格式：必须是以字母开头，只能包含字母数字下划线和减号，4到16位"
      //   );
      //   return;
      // }
      // if (!/^(\w){6,20}$/.test(this.info.psw)) {
      //   // 密码检验
      //   this.$message.error(
      //     "请输入正确的密码格式：只能输入6-20个字母、数字、下划线"
      //   );
      //   return;
      // }

      // if (!/^(\w){6,20}$/.test(this.info.psw2)) {
      //   // 确认密码校验
      //   this.$message.error(
      //     "请输入正确的确认密码格式：只能输入6-20个字母、数字、下划线"
      //   );
      //   return;
      // }

      if (this.info.psw != this.info.psw2) {
        // 密码校验2
        this.$message.error("两次密码不同， 请重新输入");
        return;
      }

      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.info.phone)) {
        //正则校验手机号是否合法
        this.$message.error("手机号错误");
        return;
      }
      if (this.info.code === "") {
        this.$message.error("请输入验证码");
        return;
      }
      request({
        url: "/login/updatePassword",
        method: "POST",
        data: {
          phone: this.info.phone,
          psw: this.info.psw,
          sms_code: this.info.code,
        },
      })
        .then((res) => {
          if (res.code === 0) {
            // 注册成功
            this.$alert("修改密码成功，点击确定按钮后将会跳转到登录页面。", "提示", {
              confirmButtonText: "确定",
              callback: () => {
                this.$router.push("/login");
              },
            });
          } else {
            this.$message.error(res.msg); // 未知错误
          }
        })
        .catch((err) => {
          this.$message.error(err); // 未知错误
          // console.log(err, "123");
        });
    }),
  },
};
</script>

<style scoped>
.phone-box {
  position: relative;
  /* overflow: hidden; */
}

.getYZM {
  position: absolute;
  right: 13px;
  top: 13px;
  z-index: 999;
  cursor: pointer;
  padding-left: 10px;
  border-left: 1px solid rgb(235, 235, 235);
}

.getYZM:active {
  color: #1890ff;
}
</style>
